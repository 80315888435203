<template>
    <table style="min-width:250px;min-height:45px;" class="m0 p0">
      <thead class="p0 m0">
        <th class="text-left p0 m0">Naam</th>
        <th class="text-center p0 m0">Ingekocht</th>
        <th class="text-center p0 m0">Ontvangen</th>
        <th class="text-center p0 m0">Verkocht</th>
        <th class="text-center p0 m0">Afgeleverd</th>
      </thead>
      <tbody>
        <tr style="background-color:transparent; color: white;">
          <td class="p0 m0"><strong>Trucks</strong></td>
          <td class="text-center p0 m0">{{ header_trucks.bought }}</td>
          <td class="text-center p0 m0">{{ header_trucks.recieved }}</td>
          <td class="text-center p0 m0">{{ header_trucks.sales }}</td>
          <td class="text-center p0 m0">{{ header_trucks.delivered }}</td>
        </tr>
        <tr style="background-color:transparent; color: white;">
          <td class="p0 m0"><strong>Trailers</strong></td>
          <td class="text-center p0 m0">{{ header_trailers.bought }}</td>
          <td class="text-center p0 m0">{{ header_trailers.recieved }}</td>
          <td class="text-center p0 m0">{{ header_trailers.sales }}</td>
          <td class="text-center p0 m0">{{ header_trailers.delivered }}</td>
        </tr>
        <tr style="background-color:transparent; color: white;">
          <td class="p0 m0"><strong>Vans</strong></td>
          <td class="text-center p0 m0">{{ header_vans.bought }}</td>
          <td class="text-center p0 m0">{{ header_vans.recieved }}</td>
          <td class="text-center p0 m0">{{ header_vans.sales }}</td>
          <td class="text-center p0 m0">{{ header_vans.delivered }}</td>
        </tr>

      </tbody>
    </table>
</template>

<script>
export default {
  props: {
    header_vans: Object,
    header_trucks: Object,
    header_trailers: Object,
  },
};
</script>
