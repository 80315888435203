<template>
  <header>
    <Loading v-if="loading" class="_navbar" />
    <div v-else class="_navbar">
      <router-link to="/">
        <img class="image--logo mt-2" src="@/assets/logo-kleyn-group.svg" />
      </router-link>

      <nav class="p0 m0 flex w100" id="navbar-desktop">
        <div class="flex flex-auto">
          <DropdownButton
            v-for="(menu_data, key) in this.$router.options.nav_items"
            :key="key"
            :menu_data="menu_data"
          />
          <div class="dropdown dropdown-br">
            <router-link :to="{ name: 'programs' }"
              ><button class="dropbtn">Programma's</button></router-link
            >
          </div>
        </div>
        <div class="header-table">
          <FrontpageHeader
            v-if="header_vans && header_trucks && header_trailers"
            :header_vans="header_vans"
            :header_trucks="header_trucks"
            :header_trailers="header_trailers"
          />
        </div>

        <div class="flex">
          <div class="m1 nav--search">
            <div
              v-if="isSearching == true"
              class="dot-collision loading_position"
            ></div>
            <input
              v-model="searchquery"
              placeholder="Zoeken..."
              @keydown.enter="search(searchquery)"
              id="search_mobile"
            />
            <button class="nav--button" @click="search(searchquery)">
              <i class="fa fa-search" />
            </button>
          </div>
          <Profile />
        </div>
      </nav>

      <nav class="p0 m0 flex w100 flex-end" id="navbar-mobile">
        <div class="m1 nav--search">
          <div
            v-if="isSearching == true"
            class="dot-collision loading_position_mobile"
          ></div>
          <input
            class="nav--mobile-input"
            v-model="searchquery"
            placeholder="Zoeken..."
            @keydown.enter="search(searchquery)"
            id="search"
          />
          <button class="nav--button" @click="search(searchquery)">
            <i class="fa fa-search" />
          </button>
        </div>
        <div class="flex">
          <button class="nav--open" @click="open()">
            <i class="fa fa-bars" />
          </button>
        </div>
        <div class="nav--dropdown">
          <button class="nav--close" @click="close()">
            <i class="fa fa-times" />
          </button>
          <ProfileMobile />
          <DropdownButtonMobile
            v-for="(menu_data, key) in this.$router.options.nav_items"
            :key="key"
            :menu_data="menu_data"
            :index="key"
          />
        </div>
      </nav>
    </div>
  </header>
</template>
<script>
import DropdownButton from "@/components/navbar/DropdownButton.vue";
import DropdownButtonMobile from "@/components/navbar/DropdownButtonMobile.vue";
import FrontpageHeader from "@/components/frontpage/FrontpageHeader";
import Profile from "@/components/navbar/Profile.vue";
import ProfileMobile from "@/components/navbar/ProfileMobile.vue";
import request from "@/functions/request.js";
import router from "@/router";
import Loading from "@/components/Loading.vue";

export default {
  components: {
    DropdownButton,
    DropdownButtonMobile,
    Profile,
    ProfileMobile,
    FrontpageHeader,
    Loading,
  },
  name: "Navbar",
  data: () => ({
    searchquery: null,
    isActive: false,
    selected: "ItemPage",
    isSearching: false,
    header_vans: null,
    header_trucks: null,
    header_trailers: null,
    loading: true,
  }),
  created() {
    const date = this.moment();
    this.week_number = date.week();
    this.year_number = date.year();

    request(
      `frontpage/header/${this.year_number}/${this.week_number}`,
      "GET"
    ).then(({ header_trucks, header_vans, header_trailers }) => {
      this.header_vans = header_vans;
      this.header_trucks = header_trucks;
      this.header_trailers = header_trailers;
    });
    this.isLoading();
  },
  methods: {
    isLoading() {
      if (this.loading) {
        setTimeout(() => {
          const a = this.msalInstance.getAllAccounts()[0]
          if(a){
            this.loading = false;
          } else {
            this.isLoading();
          }
        }, 1000);
      }
    },
    open() {
      const dropdown = document.getElementsByClassName("nav--dropdown")[0];
      dropdown.classList.add("nav--show");
    },
    close() {
      const dropdown = document.getElementsByClassName("nav--dropdown")[0];
      dropdown.classList.remove("nav--show");
    },
    search(searchquery) {
      if (searchquery && !this.isSearching) {
        this.isSearching = true;
        document.getElementById("search").setAttribute("disabled", "disabled");
        document
          .getElementById("search_mobile")
          .setAttribute("disabled", "disabled");
        request(`search/${searchquery}`, "GET")
          .then(({ item_page, klant_pagina, lev_page }) => {
            if (!item_page.length && !klant_pagina.length && !lev_page.length) {
              alert("No results");
            } else if (
              item_page.length == 1 &&
              !klant_pagina.length &&
              !lev_page.length
            ) {
              router.push({
                name: "ItemPage",
                params: {
                  id: String(item_page[0].nummer),
                  bu: String(item_page[0].bu),
                },
              });
            } else if (
              !item_page.length &&
              !lev_page.length &&
              klant_pagina.length == 1
            ) {
              router.push({
                name: "klantpagina",
                params: { id: String(klant_pagina[0].nummer) },
              });
            } else if (
              !item_page.length &&
              lev_page.length == 1 &&
              !klant_pagina.length 
            ) {
              router.push({
                name: "leverancier",
                params: { id: String(lev_page[0].nummer) },
              });
            } else {
              router.replace({
                name: "searchpage",
                params: { searchdata: [item_page, klant_pagina, lev_page] },
                query: { search: searchquery },
              });
            }
          })
          .then(() => {
            this.isSearching = false;
            document.getElementById("search").removeAttribute("disabled");
            document
              .getElementById("search_mobile")
              .removeAttribute("disabled");
            this.searchquery = null;
          });
      }
    },
  },
};
</script>
